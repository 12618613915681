import React from "react";
import { Paper, Typography, Box } from "@material-ui/core";

const Privacy = (props) => {
  return (
    <Box style={{ marginBottom: 200 }}>
      <Paper style={{ marginLeft: 12, marginRight: 12 }} elevation={0}>
        <Typography variant="h3">Privacy Policy</Typography>
        <Typography>
          This Privacy Policy describes how your personal information is
          collected, used, and shared when you visit or make a purchase from
          https://www.tahelpya.com (the “Site”). ​
        </Typography>

        <Typography variant="h6">PERSONAL INFORMATION WE COLLECT ​</Typography>
        <Typography>
          When you visit the Site, we automatically collect certain information
          about your device, including information about your web browser, IP
          address, time zone, and some of the cookies that are installed on your
          device. Additionally, as you browse the Site, we collect information
          about the individual web pages or products that you view, what
          websites or search terms referred you to the Site, and information
          about how you interact with the Site. We refer to this
          automatically-collected information as “Device Information.” We
          collect Device Information using the following technologies: ​ -
          “Cookies” are data files that are placed on your device or computer
          and often include an anonymous unique identifier. For more information
          about cookies, and how to disable cookies, visit
          http://www.allaboutcookies.org. - “Log files” track actions occurring
          on the Site, and collect data including your IP address, browser type,
          Internet service provider, referring/exit pages, and date/time stamps.
        </Typography>

        <Typography variant="h6">
          HOW DO WE USE YOUR PERSONAL INFORMATION? ​
        </Typography>

        <Typography>
          We use your information for the following: Communicate with you; We
          use the Device Information that we collect to help us screen for
          potential risk and fraud (in particular, your IP address), and more
          generally to improve and optimize our Site (for example, by generating
          analytics about how our customers browse and interact with the Site,
          and to assess the success of our marketing and advertising campaigns).
          ​ We share your Personal Information with third parties to help us use
          your Personal Information, as described above. For example, we use
          Google Analytics to help us understand how our customers use the
          Site--you can read more about how Google uses your Personal
          Information here: https://www.google.com/intl/en/policies/privacy/.
          You can also opt-out of Google Analytics here:
          https://tools.google.com/dlpage/gaoptout. ​ Finally, we may also share
          your Personal Information to comply with applicable laws and
          regulations, to respond to a subpoena, search warrant or other lawful
          request for information we receive, or to otherwise protect our
          rights. If you are a European resident, you have the right to access
          personal information we hold about you and to ask that your personal
          information be corrected, updated, or deleted. If you would like to
          exercise this right, please contact us through the contact information
          below. Additionally, if you are a European resident we note that we
          are processing your information in order to fulfill contracts we might
          have with you (for example if you make an order through the Site), or
          otherwise to pursue our legitimate business interests listed above.
          Additionally, please note that your information will be transferred
          outside of Europe, including to Canada and the United States. ​
        </Typography>
        <Typography variant="h6">CHANGES</Typography>
        <Typography>
          We may update this privacy policy from time to time in order to
          reflect, for example, changes to our practices or for other
          operational, legal or regulatory reasons. ​ CONTACT US For more
          information about our privacy practices, if you have questions, or if
          you would like to make a complaint, please contact us by e-mail at
          support@tahelpya.com or by mail using the details provided below: ​
          1840 Shaw Avenue 105-104, Clovis, CA, 93611, United States
        </Typography>
      </Paper>
    </Box>
  );
};

export default Privacy;
