import React from "react";
import {
  Paper,
  Typography,
  Box,
  Container,
  Grid,
  Divider,
} from "@material-ui/core";
import logo from "../../assets/Logo_Concept_150_150.png";

const Home = (props) => {
  return (
    <Container>
      <Box style={{ margin: 12 }}>
        <Grid container spacing={2}>
          <Grid item>
            <img src={logo} alt="Simple Log Logo" height="150" width="150" />
          </Grid>
          <Grid item xs={8} justify="flex-start">
            <Typography variant="h3">
              Simple Solutions for Mobile and Web
            </Typography>
            <Typography variant="body1" gutterBottom>
              TaHelpYa is dedicated to making clean, well-designed apps to help
              you get things done.​
            </Typography>
            <Typography variant="body1" gutterBottom>
              We are living in the mobile age, and rarely tied to our desks. We
              need apps that go with us and help us get things done wherever we
              are. TaHelpYa is dedicated to designing and creating simple mobile
              (iOS/Android) solutions for everyday needs.
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Divider></Divider>
      <Box style={{ margin: 32 }}>
        <Grid xs={12} container>
          <Grid item xs style={{ margin: 12 }}>
            <Typography variant="h5">The only apps you'll ever need</Typography>

            <Typography variant="body1">
              All tools in the TaHelpYa suite are simple and well designed.
              Clean apps suited to a specific purposes.The only mobile tools you
              need to get your business more efficient, and to work effectively.
            </Typography>
          </Grid>
          <Grid xs item style={{ margin: 12 }}>
            <Typography variant="h5">Outstanding Design</Typography>

            <Typography variant="body1">
              All tools in the TaHelpYa suite are simple and well designed.
              Clean apps suited to a specific purposes.The only mobile tools you
              need to get your business more efficient, and to work effectively.
            </Typography>
          </Grid>
        </Grid>

        <Grid xs={12} container>
          <Grid xs item style={{ margin: 12 }}>
            <Typography variant="h5">Customers are #1</Typography>
            <Typography variant="body1">
              Our customers are #1, and listening to their needs and issues are
              our top priority. Feedback is taken to heart and nothing is
              ignored. Feel free to contact us anytime. We'd love to hear from
              you.
            </Typography>
          </Grid>

          <Grid xs item style={{ margin: 12 }}>
            <Typography variant="h5">New Ideas</Typography>
            <Typography variant="body1">
              We are always on the look out for new ways to simplify our digital
              lives. Do you have the next great idea? Reach out to us, we'd love
              to help you develop your next software product, for web, iOS, and
              more. We will work through the User Experience and help you
              determine the design that gets your business going.
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default Home;
