import React from "react";
import "./App.css";
import { AppBar, Typography, Grid, Button, Box } from "@material-ui/core";
import {
  createMuiTheme,
  ThemeProvider,
  makeStyles,
} from "@material-ui/core/styles";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { orange, blue } from "@material-ui/core/colors";

import Home from "./pages/home/home";
import MorseZappHome from "./pages/morsezapp/morsezapphome";
import SimpleLogHome from "./pages/simple_log/simple_log_home";
import SimpleProfitHome from "./pages/simple_profit/simple_profit_home";
import Privacy from "./pages/privacy/privacy";
import MorseZappTermsOfService from "./pages/morsezapp-terms-of-service/morsezapp-terms-of-service";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  innerBox: {
    margin: 12,
  },
  appBar: {
    top: "auto",
    bottom: 0,
    minHeight: 80,
  },
}));

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#202020",
    },
    secondary: {
      main: "#f44336",
    },
  },
});

const Footer = (props) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Box className={classes.innerBox}>
          <Grid>
            <Typography gutterBottom>Contact Us</Typography>
            <Typography gutterBottom>support@tahelpya.com</Typography>
            <Typography gutterBottom style={{ fontSize: 10 }}>
              Copyright 2020, Barry Velasquez
            </Typography>
          </Grid>
        </Box>
      </AppBar>
    </Box>
  );
};

const App = () => {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <Box>
          <AppBar position="static">
            <Typography
              style={{ marginLeft: 12, marginRight: 12 }}
              variant="h2"
            >
              TaHelpYa Solutions
            </Typography>
            <Grid style={{ marginLeft: 12, marginRight: 12 }}>
              <Button>
                <Link style={{ color: "white", textDecoration: "none" }} to="/">
                  Home
                </Link>
              </Button>
              <Button>
                <Link
                  style={{ color: "white", textDecoration: "none" }}
                  to="/morsezapp"
                >
                  MorseZapp
                </Link>
              </Button>
              <Button>
                <a
                  style={{ color: "white", textDecoration: "none" }}
                  href="https://simplelog3.com"
                  target="_blank"
                >
                  Simple Log
                </a>
              </Button>
            </Grid>
          </AppBar>
        </Box>

        <Footer></Footer>

        <Route exact path="/" component={Home}></Route>
        <Route path="/morsezapp" component={MorseZappHome}></Route>
        <Route path="/simple_log" component={SimpleLogHome}></Route>
        <Route path="/simple_profit" component={SimpleProfitHome}></Route>
        <Route path="/privacy" component={Privacy}></Route>
        <Route
          path="/morsezapp-terms-of-service"
          component={MorseZappTermsOfService}
        ></Route>
      </ThemeProvider>
    </Router>
  );
};

export default App;
