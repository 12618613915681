import React from "react";
import { Paper, Typography, Box } from "@material-ui/core";

const MorseZappTermsOfService = (props) => {
  return (
    <Box style={{ marginBottom: 200 }}>
      <Paper style={{ marginLeft: 12, marginRight: 12 }} elevation={0}>
        <Typography variant="h3">MorseZapp Terms of Service</Typography>
        Terms of Service Please read these terms and conditions ("terms", "terms
        and conditions") carefully before using the MorseZapp App (the
        "service") operated by TaHelpYa / Barry Velasquez ("us", 'we", "our",
        "the owner").
        <Typography variant="h6">Conditions of Use</Typography>
        We will provide the services to you, which are subject to the conditions
        stated below in this document. Every time you use the MorseZapp App, use
        its services or make a purchase, you accept the following conditions.
        This is why we urge you to read them carefully.
        <Typography variant="h6">Privacy Policy</Typography>
        Before you continue using our service we advise you to read our privacy
        policy regarding our user data collection. It will help you better
        understand our practices.
        <Typography variant="h6">Copyright</Typography>
        Content published on service (digital downloads, images, texts,
        graphics, logos) is the property of the owner and/or its content
        creators and protected by international copyright laws. The entire
        compilation of the content found on the service is the exclusive
        property of the owner, with copyright authorship for this compilation by
        the owner.
        <Typography variant="h6">Communications</Typography>
        <Typography>
          The entire communication with us is electronic. Every time you send us
          an email, message or visit our website, you are going to be
          communicating with us. You hereby consent to receive communications
          from us. If you subscribe to the service, news on our website, you are
          going to receive regular emails or other communications from us. We
          will continue to communicate with you by posting news and notices on
          our app and website and by sending you emails. You also agree that all
          notices, disclosures, agreements and other communications we provide
          to you electronically meet the legal requirements that such
          communications be in writing.
        </Typography>
        <Typography variant="h6">Applicable Law</Typography>
        <Typography>
          By using the service, you agree that the laws of the United States and
          California, without regard to principles of conflict laws, will govern
          these terms and conditions, or any dispute of any sort that might come
          between MorseZapp/TahelpYa and you, or its business partners and
          associates.
        </Typography>
        <Typography variant="h6">Disputes</Typography>
        <Typography>
          Any dispute related in any way to your use of the service or to
          products you purchase from us shall be arbitrated by state or federal
          court of the United States and you consent to exclusive jurisdiction
          and venue of such courts.
        </Typography>
        <Typography variant="h6">Comments, Reviews, and Emails</Typography>
        <Typography>
          Visitors may post content as long as it is not obscene, illegal,
          defamatory, threatening, infringing of intellectual property rights,
          invasive of privacy or injurious in any other way to third parties.
          Content has to be free of software viruses, political campaign, and
          commercial solicitation. We reserve all rights (but not the
          obligation) to remove and/or edit such content. When you post your
          content, you grant us non-exclusive, royalty-free and irrevocable
          right to use, reproduce, publish, modify such content throughout the
          world in any media.
        </Typography>
        <Typography variant="h6">License and Site Access</Typography>
        <Typography>
          We grant you a limited license to access and make personal use of the
          service. You are not allowed to download or modify it. This may be
          done only with written consent from us.
        </Typography>
        <Typography variant="h6">User Account</Typography>
        <Typography>
          If you are an owner of an account on the service, you are solely
          responsible for maintaining the confidentiality of your private user
          details (username and password). You are responsible for all
          activities that occur under your account or password. We reserve all
          rights to terminate accounts, edit or remove content and cancel orders
          in their sole discretion.
        </Typography>
        ​<Typography variant="h6">Change of Service</Typography>​
        <Typography>
          We reserve the right modify the service at any time, including
          modifying or removing data that is used to provide the service.
        </Typography>
        ​<Typography variant="h6">Termination of Service</Typography>​
        <Typography>
          We reserve the right to terminate the service at any time. 
          Cancellation of the service includes removing the App from all
          distribution services, and removing data, including user created data,
          from all storage methods.
        </Typography>
      </Paper>
    </Box>
  );
};

export default MorseZappTermsOfService;
