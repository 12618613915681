import React from "react";
import { Paper, Typography, Box } from "@material-ui/core";

const SimpleLogHome = props => {
  return (
    <Box>
      <Paper style={{ marginLeft: 12, marginRight: 12 }} elevation={0}>
        <Typography variant="h3">Simple Log Home</Typography>
      </Paper>
    </Box>
  );
};

export default SimpleLogHome;
