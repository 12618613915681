import React from "react";
import { Paper, Typography, Box, Grid } from "@material-ui/core";
import iphoneImage from "../../assets/iphonescreen.jpg";

const MorseZappHome = (props) => {
  return (
    <Box style={{ textAlign: "center", marginBottom: 200, marginTop: 50 }}>
      <Grid
        container
        spacing={2}
        justify="center"
        alignContent="center"
        alignItems="center"
      >
        <Grid item style={{}} xs={8}>
          <Typography variant="h3">MorseZapp</Typography>
          <Typography variant="h5" style={{ marginBottom: 20 }}>
            A great way to learn Morse Code
          </Typography>
          <Typography>
            MorseZapp is a great way for learn morse code. A new kind of morse
            code / CW trainer. Each level challenges you to learn the correct
            sequence of dots and dashes to zap the letter before it hits the
            bottom. You tap the dot and dash sequence on the tap pad at the
            bottom. Sound hints and visual hints help you with beginner levels.
          </Typography>
          <Box>
            <img height="500" src={iphoneImage} />
          </Box>
        </Grid>

        <Grid item style={{}} xs={8}>
          <Typography variant="h6">LEARN THE LETTERS</Typography>
          <Typography>
            MorseZapp progresses you through the letters, increasing the
            challenge to tap out the correct sequence before it's too late.
          </Typography>
        </Grid>

        <Grid item style={{}} xs={8}>
          <Typography variant="h6">SIMPLE CHALLENGE</Typography>
          <Typography>
            MorseZapp will play the sound of the dots and dashes. You must
            select the correct letter before it hits the bottom.
          </Typography>
        </Grid>

        <Grid item style={{}} xs={8}>
          <Typography variant="h6">PACKAGES AND LEVELS</Typography>
          <Typography>
            Several packages to choose from. Each package helps you learn
            specific letter sequences, numbers, and words to increase your morse
            code skills.
          </Typography>
        </Grid>

        <Grid item style={{}} xs={8}>
          <Typography variant="h6">MORE</Typography>
          <Typography>
            More packages will be added all the time. Don't see a package you
            want? MorseZapp allows you to create your own custom levels.
          </Typography>
        </Grid>

        <Grid item style={{}} xs={8}>
          <Typography variant="h6">TRY THE BETA</Typography>
          <Typography>
            Want to try the latest features and help us develop new features.
            Join the beta by clicking the link below on your iOS device.
          </Typography>
          <a
            href="https://testflight.apple.com/join/8CFowBzf"
            target="_blank"
            data-content="https://testflight.apple.com/join/8CFowBzf"
            data-type="external"
            rel="noopener"
            id="comp-jwmbrif6link"
            class="style-jrkxji2nlink"
          >
            <span id="comp-jwmbrif6label" class="style-jrkxji2nlabel">
              Try the Beta
            </span>
          </a>
        </Grid>

        <Grid item style={{}} xs={8}>
          <iframe
            src="https://discordapp.com/widget?id=460168227377381387&theme=dark"
            width="350"
            height="500"
            allowtransparency="true"
            frameborder="0"
            sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
          ></iframe>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MorseZappHome;
